


































import { Component, Vue, Watch, PropSync, Prop } from "vue-property-decorator";
@Component({
  components: {},
})
export default class TableSelect extends Vue {
  @Prop() value!: any[];
  @Prop() itemKey!: string;
  @Prop() titulo!: string;
  @Prop() height!: number;
  @Prop({ default: false }) hide_footer!: boolean;
  @PropSync("data_selected", { default: [] }) selected!: any;
  @PropSync("data", { default: [] }) datos!: any;
  @PropSync("headers", { default: [] }) headerssync!: any;
  public search: string = "";

  @Watch("selected")
  public syncmodel() {
    this.$emit("input", this.selected);
  }

  public Seleccion(e: any) {
    if (e.value) {
      this.$emit("item-seleccionado", e.item);
    } else {
      this.$emit("item-deseleccionado", e.item);
    }
  }
}
